import React from "react";
import { graphql } from "gatsby";
import { Wrapper } from "../components/site/Wrapper";
import { Image } from "../components/site/Image";
import { Button } from "../components/site/Button";
import { Link } from "@gatsbyjs/reach-router";
import { sortTheBooks } from "./books";
const comingSoonImage = "/img/books/coming-soon-book-cover-100x130.png";

const Books = (props:any) => {
  
  const books = props?.data?.allBooksJson?.edges;

  const Book = (data:any) => {
    const {comingSoon} = data;
    const image = comingSoon ? comingSoonImage : data.image;
    return (
    <div className="col-md-6 row p-3">
      <div className="d-flex flex-row">
        <div className="">
          <Link to={`/keys/${data.puzzleType}/${data.slug}`}>
            <Image src={image} className={`rounded float-start gx-1`} />
          </Link>
        </div>
        <div className="px-3">
          <h2 className="fw-bold text-uppercase">{data.title}</h2>
          <h3 className="fs-6">{data.subTitle}</h3>
        </div>
      </div>
      <div className="d-grid py-3 gap-2">
          <Button as={Link} to={`/keys/${data.puzzleType}/${data.slug}`}>
            View Answers
          </Button>
        </div>
    </div>
  );
    }
  const sortedBooks = sortTheBooks(books).filter(item=>!item.node.comingSoon)

  return (
    <Wrapper>
    <div className="container gx-1">
      <h1 className="fw-bold">Answer Keys</h1>
      <div className="d-flex flex-column flex-md-row flex-wrap">
      {sortedBooks?.map((book:any) => (
        book.node.published ? <Book key={book.node.title} {...book.node} />: null
      ))}
    </div>
    </div>
    </Wrapper>
  );
};

export const query = graphql`
  # query will go here
  {
    allBooksJson {
      edges {
        node {
          title
          puzzleType
          subTitle
          slug
          image
          amazonUrl
          amazonPrice
          sortLevel
          comingSoon
          shortUrl
          published
          pages
        }
      }
    }
  }
`;

export default Books;
